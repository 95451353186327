

@mixin md {
    @media (max-width: 900px) {
        @content;
    }
}

@mixin sm {
    @media (max-width: 770px) {
        @content;
    }
}


.chart {
    position: relative;
    max-width: 55%;
    width: 100vw;
    height: 35%;
    top: 16%;
    text-transform: uppercase;
    
    @include md { max-width:57%; top:20%; height:45%; }
    @include sm { max-width:100%; top:0; height:28%;}

    div.bar-holder {
        display:flex;
        height:100%;
        justify-content: space-around;

        div.bar {
            width:10%;
            height:100%;
            align-self: end;
            position: relative;
            // background-color: var(--gold);
            background: linear-gradient(180deg,#c4beb5,#a19789 30%, #514d46);

            transition:height 1s ease-in-out, background-color 0.5s;

            &:hover {
                cursor: pointer;
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%);

                h4 {
                    opacity:1;
                    translate: 0 -97%;
                }
            }

            h4 {
                opacity:0;
                // color:black;
                font-size:1.2em;
                color:white;
                // margin-top:-30%;
                translate: 0 -90%;
                transition:opacity 0.5s, translate 0.5s;

                @include md {
                    font-size:1em;
                }
                @include sm {
                    font-size:0.9em;
                }
            }

            button {
                position:absolute;
                outline: none;
                border: none;
                border-radius: 50%;
                width:2rem;
                height:2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.5rem;
                bottom:1rem;
                left:50%;
                translate: -50% 0;
                background-color: #e7e7e8;
                user-select: none;

                &:hover {
                    cursor: pointer;
                    background-color: var(--gold);
                    color:white;
                }
            }
        }
    }
    div.label-holder {
        display:flex;
        justify-content: space-around;
        
        h4 {
            width:20%;
            color:white;
            margin-top: 1em;
            line-height:1.25em;

            @include md {
                width:20%;
                font-size:0.8em;
            }
            
            @include sm {
                width:20%;
                font-size:0.8em;
            }
        }
        img {
            width: 100%;
        }
    }
}

.border-bottom {
    width:100%;
    height:1px;
    // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A19789FF' stroke-width='2' stroke-dasharray='3%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
