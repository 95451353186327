:root {
    --background-color: #2d2d2b;
    --background-color-lighter: #393936;
    --background-color-hover: #41413f;
    --background-color-fade: hsla(60, 2%, 17%, 0.4);
    --background-color-transparent: hsla(60, 2%, 17%, 0.4);
    --gold: #a19789;
    
    --white: #fff;
}

@import url('https://fonts.googleapis.com/css2?family=Arya:wght@400;700&display=swap');

body {
    overflow: hidden;
}

* {
	margin: 0;
    padding:0;
    font-family:"Arya", sans-serif;
    box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.text-center {
    text-align: center;
}

.wrapper {
    
    height: 100vh;
    width: 100vw;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

div.container {
    background-color: var(--background-color);
    // max-width:1140px;
    width: 100%;
    height: 100%;
    // height:455px;
    position:relative;
    // padding:2rem;
    padding:0 2rem;

    h3 {
        text-transform: uppercase;
        color:var(--gold);
        margin-bottom:1em;
    }

    h2 {
        font-size:2rem;
        color:white;
    }
}


div.modal-container {
    position:absolute;
    height: 100%;
    width: 100%;
    top:0;
    left:0;
    color: var(--white);
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    text-align: center;

    button.close {
        position:absolute;
        font-weight:bold;
        right: 1rem;
        top: 1rem;
        border: none;
        outline: none;
        font-size:1.5rem;
        background-color: transparent;

        &:hover {
            cursor: pointer;
        }
    }

    div.overlay {
        position:absolute;
        width:100%;
        height:100%;
        background-color: var(--background-color-fade);
        backdrop-filter: blur(2px);
        cursor: pointer;
    }

    div.modal {
        background-color: #e7e7e8;
        padding:2rem;
        position: absolute;
        min-width: 550px;
        max-width: 550px;
        width: 40%;
        left: 50%;
        top: 50%;
        translate: -50% -50%;

        h3 {
            color: black;
            font-size: 1.5rem;
            margin-bottom: 2em;
        }
        
        p {
            margin-bottom: 1em;
        }
        
        button:not(.close) {
            background-color: black;
            color: white;
            border: none;
            outline:none;
            padding: 0.75em 2em;
            margin-top: 1em;
            font-size: 0.9em;
            border-radius: 2rem;
            cursor: pointer;

            &:hover {
                background-color: var(--gold);
            }
        }

        div.payment {
            display:grid;
            grid-template-columns: 1fr 1fr 0.1fr;
            gap:1rem;
            margin-bottom: 1em;
            
            div.input-container {
                input {
                    width:60%;

                    &.error {
                        border: 1px solid red;
                    }
                }
            }
        }

        div.input-container {
            display: flex;
            justify-content: end;
            align-items: center;
            margin-bottom: 1em;
            gap:1rem;

  
            label {
                text-transform: uppercase;
                font-weight: bold;
                color:black;
            }

            input {
                display:inline-block;
                font-size: 0.9rem;
                border: none;
                outline: none;
                padding: 0.5em 1em;
                margin:0;
                width: 100%;

                &::placeholder {
                    font-size: 0.8em;
                }
            }

            &.address {
                width:90%;
                margin-left: 0.6rem;
            }
        }
    }
}

.error-message {
    color:rgb(236, 54, 4);
}

@mixin md {
    @media (max-width: 900px) {
        @content;
    }
}

@mixin sm {
    @media (max-width: 770px) {
        @content;
    }
}

#block {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    height: 71%;
    // background-color: rgb(161 151 137 / 12%);
    background-color: #1b1b1a;
    padding: 60px 55px;


    h2 {
        font-size:34px;
        font-weight: 400;
        line-height:1.2em;
        margin: 0 0 20px;
    }

    p {
        color:#ececec;
        max-width: 90%;
        line-height:1.5em;
        margin-bottom: 1.125em;
        font-size:18px;
    }

    .button {
        border-radius: 25px;
        text-align: center;
        display: inline-block;
        padding: 0 25px;
        height: 50px;
        line-height: 50px;
        min-width: 100px;
        position: relative;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        background: #a1978a;
        cursor: pointer;
        color: #000;
        a {
            color:#000;
            text-decoration: none;
        }

        .auto {
            width: auto;
        }
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .item-name {
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
        }

        h2 {
            font-size:28px;
        }
    }

    img[alt="logo"] {
        width: 25%;
        display:block;
        margin-bottom: 1rem;
        overflow: hidden;
        border-radius: 50%;
    }

    @include md {
        width:40%;
        height:80%;

        h2 {
            font-size: 28px;
        }

        p {
            font-size:16px;
        }
    }

    @include sm {
        width:100%;
        height:30%;
        top:auto;
        bottom:0;
        padding:1.5rem;

        h2 {
            font-size:1.75rem;
            margin-bottom: 0.5rem;
        }

        p {
            // line-height: 1.2;
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
        }

        .button {
            height:35px;
            line-height: 35px;
            margin-left:auto;
            flex-shrink: 0;
        }

        .item {
            flex-direction: row;
            gap:1rem;
            align-items: center;
            justify-content: start;
            height: 100%;
            padding-left: 2rem;

            .item-name {
                text-align: left;

                .button {
                    width:75%;
                    font-size:min(3.5vw, 1rem);
                    white-space: nowrap;
                }
            }

            h2 {
                font-size:min(1.5rem, 5vw)
            }

            img {
                flex-shrink: 0;
                max-width: 100px;
            }
        }
    }

}